
import { Box, } from '@mui/material';
import {FormattedMessage} from "react-intl";
import React, { useState, useEffect } from 'react';
import {isMobile} from "../utils/common.js"
import {useNavigate, useLocation} from "react-router-dom"





const MainButton = (props)=>{
    const [hovered, setHovered] = useState(false)
    const navigate = useNavigate();


    var borderButton = "1px solid #FFFFFF"
    var icon = "home/"+props.icon
    var font = "#FFFFFF"
    if (hovered) {
        borderButton = "1px solid #FCD402"
        icon = "home/"+props.icon_hovered
        font = "#FCD402"
    }
    const mouseOverHandler = ()=>{
        setHovered(true)
    }
    const mouseLeaveHandler = ()=>{
        setHovered(false)
    }
    const goTo = ()=>{
        navigate(props.path)
    }
    return (
        <div style={{display:"table", cursor: "pointer", overflow: "hidden", position: "absolute", left: props.left, top: props.top, width: props.width, height: props.height, border: borderButton}} onClick={goTo} onMouseEnter={mouseOverHandler} onMouseLeave={mouseLeaveHandler}>
            <div style={{display: "table-cell", verticalAlign: "middle", paddingLeft: "10px"}}><img src={icon} style={{display: "table-cell", verticalAlign: "middle", align: "center", float: "right", height: props.img_height}}/></div>
            <div style={{display: "table-cell", verticalAlign: "end", padding: "10px", textAlign: "left", color: font, fontSize: props.font_size, whiteSpace: 'pre-line'}}><FormattedMessage id={props.text_id} /></div>
        </div>
    )
}

/*
const Home = () => {
    return (
        <Box
            sx={{
                minHeight: '100vh',
                bgcolor: 'background.default',
                color: 'text.primary',
                justifyContent: 'center',
            }}
            style={{background: "linear-gradient(180deg, #092446 0%, #000000 100%)", overflow: "hidden", position: "relative"}}
        >
            <img src="home/ellipse_blue.png" style={{position: "absolute", left: "173px", top: "65px"}}></img>
            <img src="home/pers_man.png" style={{position: "absolute", left: "-26px", top: "-23px"}}></img>

            <img src="home/ellipse_purple.png" style={{position: "absolute", left: "1068px", top: "43px"}}></img>
            <img src="home/car.png" style={{position: "absolute", left: "1080px", top: "30px", transform: "rotate(-5.39deg)", height: "281.04px"}}></img>

            <img src="home/ellipse_green.png" style={{position: "absolute", left: "1529px", top: "492px"}}></img>
            <img src="home/pers_wooman.png" style={{position: "absolute", left: "1529px", top: "218px",}}></img>

            <div style={{position: "absolute", color: "#FFFFFF", left: "608px", top: "179px", fontFamily: "TekturExtraBold", fontStyle: "normal", fontWeight: "900", fontSize: "140px", textAlign: "start", lineHeight: "130px", textTransform: "uppercase"}}>
                <FormattedMessage id="home_title" />
            </div>
            
        </Box>)
}*/


const Home = () => {
    const [isLandscape, setIsLandscape] = useState(false)
    const updateOrientation = () => {
        if (window.screen.orientation.type.includes("landscape")) {
          setIsLandscape(true)
        } else {
          setIsLandscape(false)
        }
      }
    
      useEffect(() => {
        if (window.screen.orientation.type.includes("landscape")) {
          setIsLandscape(true)
        } else {
          setIsLandscape(false)
        }
        window.addEventListener(
          'orientationchange',
          updateOrientation
        )
        return () => {
          window.removeEventListener(
            'orientationchange',
            updateOrientation
          )
        }
      }, [])

    if (isMobile && !isLandscape) {
        return (
            <Box
                sx={{
                    minHeight: '100vh',
                    bgcolor: 'background.default',
                    color: 'text.primary',
                    justifyContent: 'center',
                }}
                style={{background: "linear-gradient(180deg, #092446 0%, #000000 100%)", overflowY: "hidden", position: "relative"}}
            >
                <img src="home/ellipse_blue.png" style={{position: "absolute", left: "-14.66666667%", top: "35.591133%", width: "66.93333333%"}}></img>
                <img src="home/pers_man.png" style={{position: "absolute", width:"106.9333333%", left: "-37.06666667%", top: "27.46305419%"}}></img>

                <img src="home/ellipse_purple.png" style={{position: "absolute", left: "32.53333333%", top: "4.113300493%", width: "27.73333333%"}}></img>
                <img src="home/car.png" style={{position: "absolute", left: "32.53333333%", top: "0%", width: "67.52533333%"}}></img>

                <img src="home/ellipse_green.png" style={{position: "absolute", left: "55.2%", top: "47.04433497%", width: "68.8%"}}></img>
                <img src="home/pers_wooman_mob.png" style={{position: "absolute", left: "57.33333333%", top: "38.54679803%", width: "50.66666667%"}}></img>

                <div style={{position: "absolute", color: "#FFFFFF", left: "9.066666667%", top: "13.30049261%", fontFamily: "TekturExtraBold", fontStyle: "normal", fontWeight: "900", fontSize: "12.8vw", textAlign: "start", lineHeight: "12.26666667vw", textTransform: "uppercase"}}>
                    <FormattedMessage id="home_title" />
                </div>
                <MainButton path={"/games"} left="10.13333333%" top="70.56650246%" width="59.73333333%" height="6.157635468" img_height="24px" font_size="3.2vw" text_id={"home_play_games"} icon="joystick_mob.png" icon_hovered="joystick_hover.png"/>
                <MainButton path={"/leaderboards"} left="10.13333333%" top="77.95566502%" width="59.73333333%" height="6.157635468" img_height="24px" font_size="3.2vw" text_id={"home_grow"} icon="star_mob.png" icon_hovered="star_hover.png"/>
                <MainButton path={"/tournaments"} left="10.13333333%" top="85.34482758%" width="59.73333333%" height="6.157635468" img_height="24px" font_size="3.2vw" text_id={"home_tournaments"} icon="trophy_mob.png" icon_hovered="trophy_hover.png"/>
            </Box> 
        )
    }
    return (
        <Box
            sx={{
                minHeight: '100vh',
                bgcolor: 'background.default',
                color: 'text.primary',
                justifyContent: 'center',
            }}
            style={{background: "linear-gradient(180deg, #092446 0%, #000000 100%)", overflow: "hidden", position: "relative"}}
        >
            <img src="home/ellipse_blue.png" style={{position: "absolute", left: "9.010416667%", top: "6.018518519%", width: "31.25%"}}></img>
            <img src="home/pers_man.png" style={{position: "absolute", width:"49.89583333%", left: "1.354166667%", top: "-2.12962963%"}}></img>

            <img src="home/ellipse_purple.png" style={{position: "absolute", left: "56.625%", top: "1.681481481%", width: "12.6125%"}}></img>
            <img src="home/car.png" style={{position: "absolute", left: "57.85%", top: "-0.377777778%", transform: "rotate(-5.39deg)", height: "31.92222222%"}}></img>

            <img src="home/ellipse_green.png" style={{position: "absolute", left: "79.63541667%", top: "45.55555556%", width: "20.36458333%"}}></img>
            <img src="home/pers_wooman.png" style={{position: "absolute", left: "79.63541667%", top: "20.18518519%", width: "20.36458333%"}}></img>

            <div style={{position: "absolute", color: "#FFFFFF", left: "31.66666667%", top: "16.57407407%", fontFamily: "TekturExtraBold", fontStyle: "normal", fontWeight: "900", fontSize: "7.291666667vw", textAlign: "start", lineHeight: "6.770833333vw", textTransform: "uppercase"}}>
                <FormattedMessage id="home_title" />
            </div>
            <MainButton path={"/games"} left="31.66666667%" top="78.61111111%" width="14.84375%" height="7.407407407%" img_height="55%" font_size="0.9375vw" text_id={"home_play_games"} icon="joystick.png" icon_hovered="joystick_hover.png"/>
            <MainButton path={"/leaderboards"} left="47.55208333%" top="78.61111111%" width="14.84375%" height="7.407407407%" img_height="55%" font_size="0.9375vw" text_id={"home_grow"} icon="star.png" icon_hovered="star_hover.png"/>
            <MainButton path={"/tournaments"} left="63.4375%" top="78.61111111%" width="17.70833333%"  height="7.407407407%" img_height="55%" font_size="0.9375vw" text_id={"home_tournaments"} icon="trophy.png" icon_hovered="trophy_hover.png"/>
        </Box>)
}

export default Home;