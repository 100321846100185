import logo from './logo.svg';
import './App.css';
import {RecoilRoot} from 'recoil';
import {BrowserRouter,Route,Routes,} from "react-router-dom"
import Space1vs1v1 from "./games/Space1vs1v1/Space1vs1v1"
import SlyBalabama from "./games/SlyBalabama/SlyBalabama"

import React, { useMemo, useState, useEffect } from 'react';
import { I18nPropvider, LOCALES } from './i18n';
import { useTheme, ThemeProvider, createTheme } from '@mui/material/styles';
import ColorModeContext from './utils/ColorModeContext';
import {domainNameAPI, protocolAPI, apiPort} from "./environment"

import Layout from './layout/Layout';
import CheckMail from "./user/auth/CheckMail"
import Games from "./games/Games"
import AuthCheck from './user/auth/AuthCheck';
import Profile from "./user/user_pages/profile/Profile"
import GetVKCodeToRegisterLogin from "./user/auth/GetVKCodeToRegisterLogin"
import SiteController from "./site_controller/SiteController"
import { Leaderboard } from '@mui/icons-material';
import Leaderboards from "./leaderboards/Leaderboards"
import ShowGame from "./games/ShowGame"
import BlocksChallenge from "./games/BlocksChallenge/BlocksChallenge"
import Tournaments from './tournaments/Tournaments';
import UserCard from "./user/user_card/UserCard"
import UserAgrementRU from "./license_pages/UserAgreementRU"
import PrivacyPolicyRU from "./license_pages/PrivacyPolicyRU"
import Subscription  from './subscriptions/Subscriptions';
import Home from "./home/Home"
import About from "./about/About"
import LegalInformation from "./layout/LegalInformation"


function App() {
  const [locale, setLocale] = useState(LOCALES.RUSSIAN); 
  
  let startTheme = 'light'
  let deviceSettings = localStorage.getItem('DeviceSettings')
  if (deviceSettings !== null) {
    deviceSettings = JSON.parse(deviceSettings)
    startTheme = deviceSettings.theme
  }

  const [mode, setMode] = useState(startTheme);
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          let newMode = 'light'
          if (prevMode === 'light') {
            newMode = 'dark'
          }
          let deviceSettings = localStorage.getItem('DeviceSettings')
          if (deviceSettings !== null) {
            deviceSettings = JSON.parse(deviceSettings)
            deviceSettings.theme = newMode
            localStorage.setItem('DeviceSettings', JSON.stringify(deviceSettings))
          } else {
            deviceSettings = {theme: newMode}
            localStorage.setItem('DeviceSettings', JSON.stringify(deviceSettings))
          }
          return newMode
        });
      },
    }),
    [],
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode],
  );
  return (
    <RecoilRoot>
      <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
      <I18nPropvider locale={locale} defaultLocale={LOCALES.RUSSIAN}>
      <div className="App">
        <AuthCheck></AuthCheck>
        <SiteController apiUrl={"wss://"+domainNameAPI+apiPort+"/api/controller/ws"}></SiteController>
          <BrowserRouter>
            <Routes>
              <Route element={<Layout />}>
                <Route path='/' element={<Home></Home>}/>
                <Route path='/about' element={<About></About>}/>
                <Route path='/check_email/:token' element={<CheckMail></CheckMail>}/>
                <Route path='/games' element={<Games></Games>}/>

                <Route path='/game_page/:gameName' element={<ShowGame />}/>

                
                <Route path='/legal_information' element={<LegalInformation></LegalInformation>}/>                
                <Route path='/user_agreement' element={<UserAgrementRU></UserAgrementRU>}/>
                <Route path='/privacy_policy' element={<PrivacyPolicyRU></PrivacyPolicyRU>}/>

                <Route path='/profile' element={<Profile></Profile>}/>
                <Route path="/getVKCodeToRegisterLogin" element={<GetVKCodeToRegisterLogin />} />  

                <Route path="/leaderboards" element={<Leaderboards />} />  

                <Route path="/tournaments" element={<Tournaments />} />  

                <Route path="/show_game/:gameName" element={<ShowGame />} />
                <Route path="/show_game_opponent/:gameName" element={<ShowGame current={true}/>} />

                <Route path='/user/:nickname' element={<UserCard></UserCard>}/>

                <Route path="/subscription" element={<Subscription />} />
                <Route path="/subscription/success/:order_id" element={<Subscription type="success"/>} />
                <Route path="/subscription/failure" element={<Subscription type="failure"/>} />
              </Route>           
            </Routes>
          </BrowserRouter>
      </div>
      </I18nPropvider>
      </ThemeProvider>
      </ColorModeContext.Provider>
    </RecoilRoot>
  );
}

export default App;
